import '../globals.css'

const WhatsApp = () => {
    return (
        <a href="https://api.whatsapp.com/send?phone=31971003909">
            <div class="wp-btn">
                <img src="assets/img/whatsapp.png" alt="WhatsApp Icon" />
            </div>
        </a>
    )
}

export default WhatsApp