import { Clock, MapPin, Smartphone } from "lucide-react";
import React from "react";
import {
  FaArrowRight,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPaperPlane,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>
      {/* footer area start */}
      <footer className='footer-area'>
        <div
          className='footer-top'
          style={{ backgroundImage: 'url("./assets/img/footer/bg.png")' }}
        >
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-4 col-md-6'>
                <div className='single-footer-top'>
                  <div className='icon'>
                    <MapPin className="text-white" size={32} />
                  </div>
                  <div className='details'>
                    <h6 className="coolvetica-regular">ONDE ESTAMOS:</h6>
                    <p>Rua Major Delfino de Paula, 1225 - São Francisco - BH/MG</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-footer-top'>
                  <div className='icon'>
                    <Smartphone className="text-white" size={32} />
                  </div>
                  <div className='details'>
                    <h6 className="coolvetica-regular">FALE CONOSCO:</h6>
                    <p>31 3654 3690</p>
                    <p>31 3058 0232</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-footer-top after-none'>
                  <div className='icon'>
                    <Clock className="text-white" size={32} />
                  </div>
                  <div className='details'>
                    <h6 className="coolvetica-regular">ASSISTENCIA 24 HORAS:</h6>
                    <p>0800 008 6548</p>
                    <p>0800 008 6969</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-xl-4 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='assets/img/logo-white.png' alt='MasterTruck' />
                </div>
                <div className='details'>
                  <p>
                  Nossa missão é oferecer proteção veicular e contribuir para causas sociais que fazem a diferença na vida das pessoas.
                  </p>
                  <ul className='social-media style-border'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaWhatsapp />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title coolvetica-regular'>LINKS</h4>
                <ul>
                  <li>
                    <Link to=''>
                      <FaArrowRight /> Fale com o presidente
                    </Link>
                  </li>
                  <li>
                    <Link to=''>
                      <FaArrowRight /> Trabalhe Conosco
                    </Link>
                  </li>
                  <li>
                    <Link to=''>
                      <FaArrowRight /> Cotação
                    </Link>
                  </li>
                  <li>
                    <Link to=''>
                      <FaArrowRight />
                      Rastreamento
                    </Link>
                  </li>
                  <li>
                    <Link to=''>
                      <FaArrowRight /> Oficinas
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title coolvetica-regular'>SERVIÇOS</h4>
                <ul>
                  <li>
                    <a href=''>
                      <FaArrowRight /> Roubo / Furto
                    </a>
                  </li>
                  <li>
                    <a href=''>
                      <FaArrowRight /> Carro Reserva
                    </a>
                  </li>
                  <li>
                    <a href=''>
                      <FaArrowRight /> Assistencia 24h
                    </a>
                  </li>
                  <li>
                    <a href=''>
                      <FaArrowRight /> Taxi
                    </a>
                  </li>
                  <li>
                    <a href=''>
                      <FaArrowRight /> Chaveiro
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer area end */}
    </>
  );
};

export default FooterOne;
