import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import ContactOne from "../components/ContactOne";
import FooterOne from "../components/FooterOne";
import FooterBottomOne from "../components/FooterBottomOne";
import NavbarOne from "../components/NavbarOne";
const TeamOne = React.lazy(() => import("../components/TeamOne"));
const Unidades = () => {
    return (
        <>
            <Fragment>
                <Suspense fallback={<Preloader />}>

                    <NavbarOne />

                    <TeamOne />

                    <div
                        className='call-to-contact-area pd-top-240'
                        style={{ background: "#F9F9F9" }}
                    >
                        <ContactOne />
                    </div>

                    <FooterOne />

                    <FooterBottomOne />
                </Suspense>
            </Fragment>
        </>
    );
};

export default Unidades;
