import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const ContactOne = () => {
  return (
    <>
      {/*contact-area start*/}
      <div>
        <img src='https://img.freepik.com/fotos-gratis/empresario-falando-ao-telefone_23-2148634804.jpg?t=st=1721654805~exp=1721658405~hmac=1f6153695fd297b13a4169e9d174e5c27e18d9a04d02821508285123bfe6770c&w=1800' style={{ objectFit: "cover",width: 1000, height: 444 }} alt='MasterTruck' />
        <div className='container'>
          <div className='row justify-content-end'>
            <div className='col-xl-6 col-lg-7'>
              <div className='cta-inner-wrap'>
                <div className='section-title style-white mb-0'>
                  <h2 className="title coolvetica-regular">
                    ALGUMA DÚVIDA? ENTRE EM CONTATO
                  </h2>
                </div>
                <div className='single-cta-wrap'>
                  <div className='icon'>
                    <FaPhoneAlt />
                  </div>
                  <div className='details'>
                    <h6>Suporte 24 horas.</h6>
                    <h3>31 3654 3690</h3>
                  </div>
                </div>
                <Link className='btn btn-base' to=''>
                  Fale conosco
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*contact-area end*/}
    </>
  );
};

export default ContactOne;
